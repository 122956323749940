<template>
  <span class="bg" />
  <div class="py-8">
    <v-img
      class="mx-auto my-10"
      max-width="300"
      src="images/logo.png"
    />
    <v-form
      ref="formRef"
      lazy-validation
    >
      <v-card
        class="mx-auto pa-15 pb-10"
        elevation="8"
        max-width="500"
        width="90vw"
        rounded="lg"
      >
        <BaseErrorMessage
          v-if="error && auth === 'invalid'"
          :error-message="errorMessage"
        />
        <div class="text-subtitle-1 text-medium-emphasis">
          Account
        </div>

        <v-text-field
          v-model="form.id"
          class="mb-4"
          density="compact"
          placeholder="Id or Email address"
          variant="outlined"
          :rules="[isRequired]"
        />
        <v-text-field
          v-model="form.password"
          autocomplete="off"
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          density="compact"
          placeholder="Enter your password"
          prepend-inner-icon="mdi-lock-outline"
          variant="outlined"
          :rules="[isRequired]"
          @click:append-inner="visible = !visible"
        />

        <v-btn
          v-if="baseStore.state.value.token"
          data-test="loginBtn"
          block
          class="mt-3 mb-8"
          color="primary"
          size="large"
          @click="validate"
        >
          ログイン
        </v-btn>

        <v-divider />

        <v-btn
          block
          size="large"
          variant="outlined"
          color="primary"
          class="mt-8"
          @click="gotoGoogleLogin"
        >
          Googleでログイン
        </v-btn>
      </v-card>
    </v-form>
    <ClientOnly>
      <v-overlay v-model="baseStore.state.value.overlay" />
    </ClientOnly>
  </div>
</template>

<script lang="ts">
import { useAuth } from '@/composables/auth';
import { VForm } from '@/types';
export default defineComponent({
  setup() {
    const { authorizationUrl, auth, idPwdLogin } = useAuth();
    const state = reactive<any>({
      form: {
        id: '',
        password: ''
      },
      visible: false,
      error: false,
      errorMessage: '認証に失敗しました。'
    });
    const formRef = ref<null | VForm>(null);
    const router = useRouter();
    const { isRequired, valid } = useFormRules();
    const baseStore = useBaseStore();

    // cookieよりログイン状態チェック
    (function (): void {
      if (auth.value !== undefined && auth.value.logedIn) {
        // トップ画面へredirect(最終的にはdashboardになるがリリースが先になるので金融機関一覧へ)
        router.push('/project/ba/bank');
      }
      if (auth.value !== undefined && auth.value === 'invalid') {
        state.errorMessage = '認証に失敗しました。';
      }
    })();

    // Googleの認証画面へリダイレクト
    const gotoGoogleLogin = async (): Promise<void> => {
      state.error = false;
      state.errorMessage = '認証に失敗しました。';
      const url = await authorizationUrl();
      if (url) {
        window.location.href = url;
      } else {
        state.errorMessage = '認証画面URL取得に失敗しました。';
        state.error = true;
      }
    };

    // ID/PWDログイン
    const validate = async (): Promise<void> => {
      // validation
      await formRef.value?.validate();

      // error
      if (formRef.value?.errors.length ?? 0 > 0) {
        state.error = true;
        return;
      }

      const data = await idPwdLogin(state.form);
      if (!data || data.status !== 'success') {
        state.error = true;
      } else {
        // トップ画面へredirect(最終的にはdashboardになるがリリースが先になるので金融機関一覧へ)
        router.push('/project/ba/bank');
      }
    };

    return {
      ...toRefs(state),
      auth,
      gotoGoogleLogin,
      formRef,
      validate,
      isRequired,
      baseStore,
      valid
    };
  }
});
</script>

<style scoped>
.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url('https://cdn.pixabay.com/photo/2016/03/26/13/09/organic-1280537_1280.jpg') no-repeat center center;
  background-size: cover;
  /* background-color: red; */
  transform: scale(1.1);
}
</style>
