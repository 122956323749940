<template>
  <Login />
</template>

<script lang="ts" setup>
definePageMeta({
  layout: 'blank'
});
useHead({ title: 'ログイン' });
</script>
